import React from 'react';
import PropTypes from 'prop-types';
import { Row, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import logoSaloriza from 'assets/images/logo-saloriza.svg';
// utils
import './styles.less';
import { getLanguages } from 'utils/lang';

const { Text } = Typography;
const BM_CODE = 'BM';
const SR_CODE = 'SR';

const SalonConnectSaloriza = ({ connectingParty, handleToggle3rdParty, showWarningDisconnect3rdParty }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const onChangeStatus = (value) => {
    if (connectingParty === BM_CODE) {
      showWarningDisconnect3rdParty(BM_CODE);
    } else {
      handleToggle3rdParty({ code: SR_CODE, connectingParty });
    }
  };

  return (
    <div style={{ marginTop: 24, display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: 240, height: 80 }}>
        <img src={logoSaloriza} className='logo-saloriza' />
      </div>
      <div style={{ marginTop: 32, marginBottom: 24 }}>
        <Text strong>{lang.procedureLinkSR}</Text>
      </div>
      <Row style={{ marginBottom: 24 }}>
        <Text>{lang.connectSRstep1} </Text>
      </Row>

      <Row style={{ marginBottom: 8, fontWeight: 500 }}>
        <Text>{lang.connectSRstep} </Text>
      </Row>
      <Row>
        <Text>{lang.connectSRstepLine1} </Text>
      </Row>
      <Row style={{ marginBottom: 32 }}>
        <Text>{lang.connectSRstepLine2} </Text>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Text>{lang.connectSRstep2} </Text>
      </Row>
      <Row>
        <Text>{lang.connectSRstepLine3} </Text>
      </Row>

      <div style={{ marginBottom: 40 }}>
        <Switch
          style={{ position: 'absolute' }}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          checked={connectingParty === SR_CODE}
          onChange={(value) => onChangeStatus(value)}
        />
      </div>
      <div>
        <Text>{lang.connectSRstepLine4}</Text>
      </div>
      <Text>{lang.connectSRstepLine5}</Text>

      <div style={{ marginTop: 32 }}>
        <Text strong>【{lang.notes}】</Text>
      </div>
      <div>
        <Text>{lang.connectSRstepNoteContent}</Text>
      </div>
    </div>
  );
};

SalonConnectSaloriza.propTypes = {
  connectingParty: PropTypes.string,
  handleToggle3rdParty: PropTypes.func,
  showWarningDisconnect3rdParty: PropTypes.func,
};
export default SalonConnectSaloriza;
