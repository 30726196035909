export const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WAITING: 0,
  CONFIRM: 1,
  REJECT: -1,
  SUSPEND: -2,
};

export const USER_GENDER = {
  FEMALE: 1,
  MALE: 2,
};

export const TYPE = {
  PERSONAL: 'PERSONAL',
  COMPANY: 'COMPANY',
};

export const SALON_STATUS = {
  REQUESTING: 0,
  APPROVED: 1,
  INACTIVED: -1,
};

export const BOOKING_STATUS = {
  CONFIRMED: { langKey: 'statusConfirmed', color: 'blue' },
  DONE: { langKey: 'statusDone', color: 'green' },
  WAITING_DONE: { langKey: 'statusWaitingDone', color: 'red' },
  CANCELED: { langKey: 'statusCanceled', color: 'orange' },
  // DECLINED_RESERVATION: { langKey: 'statusDeclinedReservation', color: 'volcano' },
  NAILIST_CANCELED: { langKey: 'statusNailistCanceled', color: 'magenta' },
  // CARD_ERROR_CANCEL: { langKey: 'statusCardErrorCancel', color: 'red' },
  // CANCELED_RESERVATION: { langKey: 'statusCanceledReservation', color: 'gold' },
  NOVISIT: { langKey: 'statusNoVisit', color: 'gold' },
  UNDONE: { langKey: 'statusUndone', color: 'purple' },
  NAILIE_CANCELED: { langKey: 'statusNailieCanceled', color: 'gray' },
  DELETED: { langKey: 'statusDeleted', color: 'default' },
};

export const THIRD_PARTIES_CODE = {
  BM: { langKey: 'beautyMerit', langKeyConnect: 'bmContentConnect', langKeyDisConnect1: 'bmContentDisconnect1', langKeyDisConnect2: 'bmContentDisconnect2', langKeyDisconnectAlert: 'bmDisConnectAlert' },
  CS: { langKey: 'comingSoon', langKeyConnect: 'csContentConnect', langKeyDisConnect1: 'csContentDisconnect1', langKeyDisConnect2: 'csContentDisconnect2', langKeyDisconnectAlert: 'csDisConnectAlert' },
  SR: { langKey: 'saloriza', langKeyConnect: 'srContentConnect', langKeyDisConnect1: 'srContentDisconnect1', langKeyDisConnect2: 'srContentDisconnect2', langKeyDisconnectAlert: 'srDisConnectAlert' },
};
export const PAYMENT_STATUS_COLORS = {
  1: '#C5EABF',
  2: '#BCDCED',
  3: '#F2473E',
  4: '#D5D4D4',
  5: '#F4D0D5',
  6: '#',
};
export const BOOKING_CANCEL = {
  NO_VISIT: 'NO_VISIT',
  NAILIST_CANCEL: 'NAILIST_CANCEL',
  EMERGENCY_CANCEL: 'EMERGENCY_CANCEL',
};

export const slots = [
  {
    value: 0,
    label: '00:00',
  },
  {
    value: 30,
    label: '00:30',
  },
  {
    value: 100,
    label: '01:00',
  },
  {
    value: 130,
    label: '01:30',
  },
  {
    value: 200,
    label: '02:00',
  },
  {
    value: 230,
    label: '02:30',
  },
  {
    value: 300,
    label: '03:00',
  },
  {
    value: 330,
    label: '03:30',
  },
  {
    value: 400,
    label: '04:00',
  },
  {
    value: 430,
    label: '04:30',
  },
  {
    value: 500,
    label: '05:00',
  },
  {
    value: 530,
    label: '05:30',
  },
  {
    value: 600,
    label: '06:00',
  },
  {
    value: 630,
    label: '06:30',
  },
  {
    value: 700,
    label: '07:00',
  },
  {
    value: 730,
    label: '07:30',
  },
  {
    value: 800,
    label: '08:00',
  },
  {
    value: 830,
    label: '08:30',
  },
  {
    value: 900,
    label: '09:00',
  },
  {
    value: 930,
    label: '09:30',
  },
  {
    value: 1000,
    label: '10:00',
  },
  {
    value: 1030,
    label: '10:30',
  },
  {
    value: 1100,
    label: '11:00',
  },
  {
    value: 1130,
    label: '11:30',
  },
  {
    value: 1200,
    label: '12:00',
  },
  {
    value: 1230,
    label: '12:30',
  },
  {
    value: 1300,
    label: '13:00',
  },
  {
    value: 1330,
    label: '13:30',
  },
  {
    value: 1400,
    label: '14:00',
  },
  {
    value: 1430,
    label: '14:30',
  },
  {
    value: 1500,
    label: '15:00',
  },
  {
    value: 1530,
    label: '15:30',
  },
  {
    value: 1600,
    label: '16:00',
  },
  {
    value: 1630,
    label: '16:30',
  },
  {
    value: 1700,
    label: '17:00',
  },
  {
    value: 1730,
    label: '17:30',
  },
  {
    value: 1800,
    label: '18:00',
  },
  {
    value: 1830,
    label: '18:30',
  },
  {
    value: 1900,
    label: '19:00',
  },
  {
    value: 1930,
    label: '19:30',
  },
  {
    value: 2000,
    label: '20:00',
  },
  {
    value: 2030,
    label: '20:30',
  },
  {
    value: 2100,
    label: '21:00',
  },
  {
    value: 2130,
    label: '21:30',
  },
  {
    value: 2200,
    label: '22:00',
  },
  {
    value: 2230,
    label: '22:30',
  },
  {
    value: 2300,
    label: '23:00',
  },
  {
    value: 2330,
    label: '23:30',
  },
];

export const BOOKING_CANCELED_STATUSES = [
  'NOVISIT',
  'DECLINED_RESERVATION',
  'NAILIE_CANCELED',
  'UNDONE',
  'CANCELED_RESERVATION',
  'NAILIST_CANCELED',
  'CARD_ERROR_CANCEL',
  'CANCELED',
];

export const BOOKING_SEARCH_STATUS = [
  ...BOOKING_CANCELED_STATUSES,
  'CONFIRMED',
  'DONE',
  'WAITING_DONE',
  'CANCELED',
];

export const PAYMENT_STATUS = {
  1: { code: 'WAITING', langKey: 'paymentStatusWaiting', color: 'blue' },
  2: { code: 'SUCCEED', langKey: 'paymentStatusSucceeded', color: 'green' },
  3: { code: 'ERROR', langKey: 'paymentStatusError', color: 'red' },
  4: { code: 'CANCELED', langKey: 'paymentStatusCanceled', color: 'orange' },
  5: { code: 'REFUNDED', langKey: 'paymentStatusRefunded', color: 'magenta' },
};
// ATONE, BANKTRANSFER, ONLINE (Stripe, veritrans) From NLSB1.7 NL-4017 Stripe => Online
export const PAYMENT_METHOD = {
  ONLINE: { code: 'ONLINE', langKey: 'paymentMethodOnline' },
  ATONE: { code: ' ATONE', langKey: 'paymentMethodOnline' },
  STRIPE: { code: 'STRIPE', langKey: 'paymentMethodOnline' }, // !!!
  BANKTRANSFER: { code: 'BANKTRANSFER', langKey: 'paymentMethodBankTransfer' },
};

export const STAFF_STATUS = {
  0: { code: 'WAITING', langKey: 'waitingForConfirm', color: 'processing' },
  1: { code: 'CONNECTED', langKey: 'connected', color: 'green' },
  2: { code: 'REJECTED', langKey: 'rejected', color: 'error' },
  3: { code: 'DISCONNECTED', langKey: 'disconnected', color: 'default' },
  4: { code: 'EXPIRED', langKey: 'expired', color: 'purple' },
  5: { code: 'CANCELED', langKey: 'canceled', color: 'volcano' },
};

export const SALES_REPORT_PERIOD = {
  firstPeriod: 'first-period',
  secondPeriod: 'second-period',
  fullMonth: 'full-month',
};

export const timezone = 'Asia/Tokyo';

export const systemId = '085094953333804';

export const LIMIT = 10;

export const MENU_REPEAT_TYPE = {
  NEW: { value: 'NEW', langText: 'menuNewText', langEditText: 'menuNewEditText' },
  REPEATER: { value: 'REPEATER', langText: 'menuRepeaterText', langEditText: 'menuRepeaterEditText' },
  ALL: { value: 'ALL', langText: 'menuAllUserText', langEditText: 'menuAllUserEditText' },
};

export const SALES_CARD_BORDER_COLOR = {
  totalSales: '#87CEFA',
  doneAmounts: '#1E90FF',
  canceledAmounts: '#4682B4',
  average: '#191970',
  countDoneNew: '#3A90FF',
  countDoneRepeater: '#3183BA',
};

export const TRANSFER_STATUS = {
  UNCOMPLETE: { langKey: 'scheduledTransfer', color: 'volcano' },
  COMPLETED: { langKey: 'transferDone', color: 'cyan' },
};

export const CUSTOMER_RANKING = {
  Bronze: { langKey: 'rankBronze', color: '#8B5C34' },
  Silver: { langKey: 'rankSilver', color: '#8E8E8E' },
  Gold: { langKey: 'rankGold', color: '#B99036' },
  Platinum: { langKey: 'rankPlatinum', color: '#E7245A' },
  Diamond: { langKey: 'rankDiamond', color: '#5E5D5C' },
};

export const BOOKING_CUSTOMER_TYPE = {
  NEW: { langKey: 'newType' },
  REPEATER: { langKey: 'repeatType' },
};
export const PRE_RANDOM_NUM = 7;

export const TAX_RATE = 10; // 10%
export const TAX_RATE_STR = '10';
export const TAX_NAILIE_COMPANY_NAME = '株式会社ネイリー';
export const TAX_COMPANY_ID = 'T9290001075228';
