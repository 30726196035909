import { useEffect, useState } from 'react';
import { getThirdPartySourceStaffList } from 'providers/StaffProvider/actions';
import { useDispatch } from 'hooks/useCustomDispatch';

const useFetchStaffsSaloriza = () => {
  const dispatch = useDispatch();
  const [staffsSaloriza, setStaffsSaloriza] = useState([]);
  const [loading, setLoading] = useState(false);
  const getStaffSalorizaList = async () => {
    setLoading(true);
    const result = await dispatch(getThirdPartySourceStaffList());
    const { data } = result;
    setStaffsSaloriza(data);
    setLoading(false);
    return data;
  };
  useEffect(() => {
    getStaffSalorizaList();
  }, []);
  return { staffsSaloriza, getStaffSalorizaList, loading };
};

export default useFetchStaffsSaloriza;
